import { Button, Col, Divider, Row, Space } from "antd"
import React from "react"
import ConfirmField from "../../../customComponents/confirmFields/ConfirmField"
import Confirm1 from "../../../customComponents/priceAndShippings/changeConfirm/Confirm1"
import Confirm2 from "../../../customComponents/priceAndShippings/changeConfirm/Confirm2"

const PriceConfirm = ({ dataInputPrice }) => {
  const processedData = {
    ...dataInputPrice,
    UnitSetting: `${dataInputPrice.UnitSetting?.Roses} / ${dataInputPrice.UnitSetting?.Case} / ${dataInputPrice.UnitSetting?.Case}`,
    SettingLoose: `${dataInputPrice.SettingLoose?.Text} / ${dataInputPrice.SettingLoose?.Select}`,
  }
  console.log("processedData", processedData)
  console.log("dataInputPrice", dataInputPrice)
  return (
    <div className="pb-10">
      <ConfirmField
        label="単位設定"
        value={processedData.UnitSetting}
        required
      />
      <ConfirmField
        label="内容量設定 バラ / pc"
        value={processedData.SettingLoose}
        required
      />
      <ConfirmField
        label="料金規格設定"
        value={processedData.PriceStandard}
        required
      />
      {processedData.PriceStandard === "定貫" && (
        <Confirm1
          processedData={processedData}
          dataInputPrice={dataInputPrice}
        />
      )}
      {processedData.PriceStandard === "不定貫" && (
        <Confirm2 processedData={processedData} />
      )}

      {processedData.ShippingMin?.Cancellation ? (
        <ConfirmField label="送料シート選択 （最小ロット）" required>
          <Col span={8}>
            <div>
              送料を無料にする:
              <span className="font-bold ml-1">
                {processedData?.ShippingMin?.Checkbox ? "無料" : "なし"}
              </span>
            </div>
            <div>
              キャンセル規定:
              <span className="ml-4">
                出荷日から
                <span className="font-bold ml-1">
                  {processedData?.ShippingMin?.Cancellation}
                </span>
                日前まで
              </span>
            </div>
            <div>
              返品規定:
              <span className="ml-4">
                到着日から
                <span className="font-bold ml-1">
                  {processedData?.ShippingMin?.Return}
                </span>
                日後まで
              </span>
            </div>
            <div>
              返品条件:
              <span className="font-bold ml-1">
                {processedData?.ShippingMin?.Policy}
              </span>
            </div>
          </Col>
          <Col span={2}>
            <div>倉庫情報:</div>
          </Col>
          <Col>
            {processedData?.ShippingMinSelects?.map((item, index) => {
              return (
                <div key={index}>
                  <div>{item?.warehouse?.name}</div>
                  <div>{item?.name_shipping}</div>
                  <Divider />
                </div>
              )
            })}
          </Col>
        </ConfirmField>
      ) : (
        <ConfirmField label="送料シート選択 （最小ロット）" required>
          {"なし"}
        </ConfirmField>
      )}
      {processedData?.ShippingMed?.Cancellation ? (
        <ConfirmField label="送料シート選択（中ロット）" required>
          <Col span={8}>
            <div>
              送料を無料にする:
              <span className="font-bold ml-1">
                {processedData?.ShippingMed?.Checkbox ? "無料" : "なし"}
              </span>
            </div>
            <div>
              キャンセル規定:
              <span className="ml-4">
                出荷日から
                <span className="font-bold ml-1">
                  {processedData?.ShippingMed?.Cancellation}
                </span>
                日前まで
              </span>
            </div>
            <div>
              返品規定:
              <span className="ml-4">
                到着日から
                <span className="font-bold ml-1">
                  {processedData?.ShippingMed?.Return}
                </span>
                日後まで
              </span>
            </div>
            <div>
              返品条件:
              <span className="font-bold ml-1">
                {processedData?.ShippingMed?.Policy}
              </span>
            </div>
          </Col>
          <Col span={2}>
            <div>倉庫情報:</div>
          </Col>
          <Col>
            {processedData?.ShippingMedSelects?.map((item, index) => {
              return (
                <div key={index}>
                  <div>{item?.warehouse?.name}</div>
                  <div>{item?.name_shipping}</div>
                  <Divider />
                </div>
              )
            })}
          </Col>
        </ConfirmField>
      ) : (
        <ConfirmField label="送料シート選択（中ロット）" required>
          {"なし"}
        </ConfirmField>
      )}
      {processedData?.ShippingLarge?.Cancellation ? (
        <ConfirmField label="送料シート選択（大ロット）" required>
          <Col span={8}>
            <div>
              送料を無料にする:
              <span className="font-bold ml-1">
                {processedData?.ShippingLarge?.Checkbox ? "無料" : "なし"}
              </span>
            </div>
            <div>
              キャンセル規定:
              <span className="ml-4">
                出荷日から
                <span className="font-bold ml-1">
                  {processedData?.ShippingLarge?.Cancellation}
                </span>
                日前まで
              </span>
            </div>
            <div>
              返品規定:
              <span className="ml-4">
                到着日から
                <span className="font-bold ml-1">
                  {processedData?.ShippingLarge?.Return}
                </span>
                日後まで
              </span>
            </div>
            <div>
              返品条件:
              <span className="font-bold ml-1">
                {processedData?.ShippingLarge?.Policy}
              </span>
            </div>
          </Col>
          <Col span={2}>
            <div>倉庫情報:</div>
          </Col>
          <Col>
            {processedData?.ShippingLargeSelects?.map((item, index) => {
              return (
                <div key={index}>
                  <div>{item?.warehouse?.name}</div>
                  <div>{item?.name_shipping}</div>
                  <Divider />
                </div>
              )
            })}
          </Col>
        </ConfirmField>
      ) : (
        <ConfirmField label="送料シート選択（大ロット）" required>
          {"なし"}
        </ConfirmField>
      )}
      <ConfirmField
        label="送料備考・ 荷姿詳細"
        value={processedData?.packing_detail}
      />
    </div>
  )
}

export default PriceConfirm
