import { Button, Col, Row, Space, Spin, Tag, Image } from "antd"
import React, { useCallback, useContext, useEffect, useState } from "react"
import ConfirmField from "../../../customComponents/confirmFields/ConfirmField"

import { AccountContext } from "../../../context/account"

const BasicInfoConfirm = ({ dataInputBasicInfo }) => {
  const [collectionTitle, setCollectionTitle] = useState()
  const { collections } = useContext(AccountContext)

  const fetchCollections = useCallback(async () => {
    if (dataInputBasicInfo && collections?.length > 0) {
      const processedData = {
        ...dataInputBasicInfo,
        collection_id_max: `${
          collections?.find(
            ({ id }) => id === dataInputBasicInfo.collection_id_max
          )?.title
        }`,
        collection_id_medium: `${
          collections
            ?.find(({ id }) => id === dataInputBasicInfo.collection_id_max)
            ?.children?.find(
              ({ id }) => id === dataInputBasicInfo.collection_id_medium
            )?.title
        } `,
        collection_id_min: `${
          collections
            ?.find(({ id }) => id === dataInputBasicInfo.collection_id_max)
            ?.children?.find(
              ({ id }) => id === dataInputBasicInfo.collection_id_medium
            )
            ?.children?.find(
              ({ id }) => id === dataInputBasicInfo.collection_id_min
            )?.title
        } `,
      }
      setCollectionTitle(processedData)
    }
  }, [dataInputBasicInfo, collections])

  useEffect(() => {
    if (collections?.length > 0) {
      fetchCollections()
    }
  }, [fetchCollections, collections])

  return (
    <div className="pb-10">
      {!collectionTitle && (
        <div className="h-[300px]">
          <Spin
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}
      {collectionTitle && (
        <div>
          <ConfirmField
            label="商品大カテゴリ"
            value={collectionTitle?.collection_id_max}
            required
          />
          <ConfirmField
            label="商品中カテゴリ"
            value={collectionTitle?.collection_id_medium}
            required
          />
          <ConfirmField
            label="商品小カテゴリ"
            value={collectionTitle?.collection_id_min}
            required
          />
          <ConfirmField
            label="商品名"
            value={collectionTitle?.title}
            required
          />
          <div>
            <Row style={{ margin: "2rem" }}>
              <Col span={6}>
                <div className="flex">
                  <Tag
                    style={{
                      height: "fit-content",
                      backgroundColor: "#E4E8EB",
                    }}
                  >
                    任意
                  </Tag>
                  <div className="flex flex-col">
                    <div className="font-bold">商品画像登録</div>
                    <div style={{ fontSize: 12 }}>最大10枚</div>
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <div className="flex flex-wrap formImag gap-[10px] max-w-[850px]">
                  {dataInputBasicInfo?.images2?.filter(
                    (ele) => ele.type == "THUMBNAIL"
                  )?.length > 0 && (
                    <Image
                      style={{
                        objectFit: "cover",
                        width: 140,
                        height: 140,
                      }}
                      src={
                        dataInputBasicInfo?.images2?.filter(
                          (ele) => ele.type == "THUMBNAIL"
                        )?.[0]?.image?.url
                      }
                    />
                  )}
                  {dataInputBasicInfo?.images2
                    ?.filter((ele) => ele.type == "DECORATE")
                    ?.map((item) => (
                      <Image
                        style={{
                          objectFit: "cover",
                          width: 140,
                          height: 140,
                        }}
                        src={item.image?.url}
                      />
                    ))}
                </div>
              </Col>
            </Row>
          </div>
          {collectionTitle?.video_url && (
            <div>
              <Row style={{ margin: "2rem" }}>
                <Col span={6} className="flex items-center">
                  <Tag
                    style={{ display: "inline", backgroundColor: "#E4E8EB" }}
                  >
                    任意
                  </Tag>
                  <div style={{ fontWeight: "bold", display: "inline" }}>
                    商品動画登録
                  </div>
                </Col>
                <Col span={16}>
                  {collectionTitle?.video_url.includes("www.youtube.com") ? (
                    <iframe
                      className="VideoInput_video"
                      height={140}
                      width={140}
                      controls
                      src={collectionTitle?.video_url}
                      allowfullscreen
                    ></iframe>
                  ) : (
                    <video
                      className="VideoInput_video"
                      // width="100%"
                      height={140}
                      width={140}
                      controls
                      src={collectionTitle?.video_url}
                    />
                  )}
                </Col>
              </Row>
            </div>
          )}
          {collectionTitle?.item_description && (
            <ConfirmField
              label="商品説明文"
              value={collectionTitle?.item_description}
            />
          )}
          {collectionTitle?.description && (
            <ConfirmField
              label="配送不可エリア "
              value={collectionTitle?.description}
            />
          )}
          {collectionTitle?.use_description && (
            <ConfirmField
              label={
                <div className="flex flex-col">
                  <div className="font-bold">召し上がり方・</div>
                  <div className="font-bold">利用方法</div>
                </div>
              }
              value={collectionTitle?.use_description}
            />
          )}
          {collectionTitle?.precaution_description && (
            <ConfirmField
              label={
                <div className="flex flex-col">
                  <div className="font-bold">使用・保管・</div>
                  <div className="font-bold">廃棄上の注意</div>
                </div>
              }
              value={collectionTitle?.precaution_description}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default BasicInfoConfirm
